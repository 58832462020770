import React from "react"
import loadable from "@loadable/component"
const DynamicChat = loadable(() => import("../../components/dynamic-popup"))

function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  return <DynamicChat />
}

export default Chat
